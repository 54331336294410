<!--
File: AmountsDropdown.vue
Description: show the dropdown combo with the amounts to select for showing.
-->
<template>
  <md-field>
    <label for="amountsDropdown">{{ label }}</label>
    <md-select v-model='selectedValue' name="amountsDropdown" id="amountsDropdown" :disabled="disabled" @md-selected='onChange'>
      <md-option v-for='(item, index) in amounts' :key='index' :value='item.id'>
        {{ item.name }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'amounts-dropdown',
    props: {
      label: { default: null, type: String },
      value: { default: null, type: Number },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,  // Инициализируем selectedValue из пропса value
        amounts: [
          { id: 1, name: this.$t("label.soms"), shortName: this.$t("label.short_soms") },
          { id: 1000, name: this.$t("label.thousands"), shortName: this.$t("label.short_thousands") },
          { id: 1000000, name: this.$t("label.millions"), shortName: this.$t("label.short_millions") }
        ],
      }
    },

    methods: {
      onChange() {
        const shortName = !this.selectedValue ? '' : this.amounts.find((el) => el.id == this.selectedValue).shortName
        this.$emit('input', this.selectedValue, shortName);
      },
    },

    watch: {
      value(newValue) { // Следим за изменениями value (v-model)
        if (newValue !== this.selectedValue) {
          this.selectedValue = newValue;
        }
      },
    }
  }
</script>  